import React from "react";
const TermsAndConditions = ({ onClose }) => {
  return (
    <div>
      <button className="close-button" onClick={onClose}>
        Close
      </button>
      <h1>The HEAL-ME digital platform and its contents</h1>
      <ol>
        <ol>
          <li>
            <p>are research based in nature</p>
          </li>
          <li>
            <p>do not represent medical/clinical care, and</p>
          </li>
          <li>
            <p>
              should not being construed or relied upon as a replacement or
              substitute for medical/clinical care or medical advice from a
              qualified healthcare professional, and that The University of
              Alberta and HEAL-ME digital platform researchers expressly
              disclaim all liability for the use of the HEAL-ME digital platform
              in this study.
            </p>
          </li>
        </ol>
      </ol>
    </div>
  );
};

export default TermsAndConditions;
