import React, { useEffect, useState } from "react";
import SignupForm from "../Components/SignupForm/SignupForm";
import MyHeartCoach from "./MyHeartCoach";
import Empower from "./Empower";
import Opal from "./Opal";
import Prehab4All from "./Prehab4All";
import Strive from "./Strive";
import Peace from "./Peace";
import "../styles/page.scss";

const GroupPage = () => {
  const queryParams = new URLSearchParams(location.search);
  const prepopulatedEmail = atob(queryParams.get("email"));
  const group = atob(queryParams.get("group"));
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLinkExpired, setIsLinkExpired] = useState(true);
  const apiBasePath = process.env.REACT_APP_PATIENT_API_BASE_PATH;
  useEffect(() => {
    fetch(`${apiBasePath}/verifyUser?email_id=${btoa(prepopulatedEmail)}`)
      .then((response) => response.json())
      .then((data) => {
        if (data["Enabled"] === true) {
          data["success"] = true;
          setApiData(data);
          const emailVerify = data.UserAttributes.find(
            (attribute) => attribute.Name === "email_verified"
          );
          if (emailVerify.Value === "false") {
            setIsLinkExpired(false);
          } else {
            setIsLinkExpired(true);
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  const handleSignupFormSubmit = (request) => {
    const formData = {
      emailId: btoa(request.email),
      password: btoa(request.password),
      isResetFlow: request.isResetFlow,
    };
    fetch(`${apiBasePath}/resetUser`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        console.log(`response: ${response}`);
        return response.json();
      })
      .then((data) => {
        console.log(`${JSON.stringify(data)}`);
        if (data && data.loginEmail) {
          sessionStorage.setItem("group", group);
          fetch(
            `${apiBasePath}/emailVerify`,
            {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                emailId: btoa(request.email),
              }),
            }
          ).then((response) => {
            const redirectTo = "login";
            window.location.href = redirectTo;
          });
        } else {
          console.log(`Error occured ${JSON.stringify(data)}`);
        }
      })
      .catch((error) => {
        console.error("Error making PUT request:", error);
      });
  };

  const renderGroupSpecificContent = () => {
    switch (group?.toLocaleLowerCase()) {
      case "empower":
        return <Empower />;
      case "myheartcoach":
        return <MyHeartCoach />;
      case "opal":
        return <Opal />;
      case "prehab4all":
        return <Prehab4All />;
      case "strive":
        return <Strive />;
      case "peace":
        return <Peace />;
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (loading) {
      return <p>Loading...</p>;
    }
    if (isLinkExpired) {
      window.location.href = `login?group=${group?.toLowerCase()}`;
      return;
    }
      return (
        <main className="front-page">
          <SignupForm
            prepopulatedEmail={prepopulatedEmail}
            onSignupSubmit={handleSignupFormSubmit}
            group={group}
            userValidated={apiData?.success}
            isLinkExpired={isLinkExpired}
          />
          {renderGroupSpecificContent()}
        </main>
      );
  };

  return <>{renderContent()}</>;
};

export default GroupPage;
