import React, { useState } from "react";
import SignupForm from "../Components/SignupForm/SignupForm";
import MyHeartCoach from "./MyHeartCoach";
import Empower from "./Empower";
import Opal from "./Opal";
import Prehab4All from "./Prehab4All";
import Strive from "./Strive";
import Peace from "./Peace";
import "../styles/page.scss";

const apiBasePath = process.env.REACT_APP_PATIENT_API_BASE_PATH;
const Login = () => {
  const [loginError, setLoginError] = useState(null);
  const queryParams = new URLSearchParams(window.location.search);
  const location = window.location.href;
  const urlArr = location.split(".");
  let group = queryParams.get("group");
  group = group ?? sessionStorage.getItem("group") ?? urlArr[1];
  let redirectUri = "";
  const handleLoginFormSubmit = (request) => {
    const formData = {
      emailId: btoa(request.email),
      password: btoa(request.password),
      rememberMe: request.rememberMe,
    };

    fetch(`${apiBasePath}/authenticateUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        console.log(`response: ${response}`);
        return response.json();
      })
      .then((data) => {
        console.log(`${JSON.stringify(data)}`);
        if (data && data?.AuthenticationResult?.IdToken) {
          console.log(
            `POST request successful:  ${data} ${data?.AuthenticationResult?.IdToken}`
          );

          const groupSessionKey = sessionStorage.key("group");
          sessionStorage.removeItem(groupSessionKey);

          let getUserGroup = getProgramGroupURL(
            data?.AuthenticationResult?.IdToken
          );

          if (
            getUserGroup.program_group.toLocaleLowerCase() ==
            group.toLocaleLowerCase()
          ) {
            window.location.href = `https://${
              getUserGroup["program_group_url"]
            }/?waa=${btoa(data?.AuthenticationResult?.IdToken)}`;
          } else {
            setLoginError(
              "The email or password you entered is incorrect. Please try again, or click 'Forgot Password?' below to reset your password."
            );
          }
        } else {
          console.log(`Error occurred ${JSON.stringify(data)}`);
          setLoginError(
            "The email or password you entered is incorrect. Please try again, or click 'Forgot Password?' below to reset your password."
          );
        }
      })
      .catch((error) => {
        console.error("Error making POST request:", error);
        setLoginError("An error occurred. Please try again later.");
      });
  };

  const handleSignupFormSubmit = (request) => {
    const formData = {
      emailId: btoa(request.email),
      password: btoa(request.password),
    };
    fetch(`${apiBasePath}/resetUser`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        console.log(`response: ${response}`);
        return response.json();
      })
      .then((data) => {
        console.log(`${JSON.stringify(data)}`);
        if (data && data === "User password set succesfully") {
          console.log("PUT request successful:", data);
          sessionStorage.setItem("group", group);
          const redirectTo = "login";
          window.location.href = redirectTo;
        } else {
          console.log(`Error occured ${JSON.stringify(data)}`);
          setLoginError("An error occurred. Please try again later.");
        }
      })
      .catch((error) => {
        console.error("Error making PUT request:", error);
        setLoginError("An error occurred. Please try again later.");
      });
  };
  const renderGroupSpecificContent = () => {
    redirectUri = "https://stg-empower-staging.kinsta.cloud/?waa=";
    switch (group?.toLocaleLowerCase()) {
      case "empower":
        redirectUri = "https://empower.wellnesstoolbox.ca/?waa=";
        return <Empower />;
      case "myheartcoach":
        redirectUri = "https://myheartcoach.wellnesstoolbox.ca/?waa=";
        return <MyHeartCoach />;
      case "opal":
        redirectUri = "https://opal.wellnesstoolbox.ca/?waa=";
        return <Opal />;
      case "prehab4all":
        redirectUri = "https://prehab4all.wellnesstoolbox.ca/?waa=";
        return <Prehab4All />;
      case "strive":
        redirectUri = "https://strive.wellnesstoolbox.ca/?waa=";
        return <Strive />;
      case "peace":
        redirectUri = "https://peace.wellnesstoolbox.ca/?waa=";
        return <Peace />;
      default:
        return null;
    }
  };

  const handleForgotPasswordSubmit = async (email) => {
    try {
      const verifyUserResponse = await fetch(
        `${apiBasePath}/verifyUser?email_id=${btoa(email)}`
      );
      const verificationData = await verifyUserResponse.json();

      if (verificationData["Enabled"] === true) {
        const formData = {
          emailId: btoa(email),
        };
        const resetPasswordResponse = await fetch(
          `${apiBasePath}/forgotPassword`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );
        const resetPasswordData = await resetPasswordResponse.json();

        if (resetPasswordData.success) {
          return { success: true };
        } else {
          console.log("Reset password link failed.");
          return { success: false };
        }
      } else {
        return { success: false };
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      return { success: false };
    }
  };

  const renderContent = () => {
    return (
      <main className="front-page">
        <SignupForm
          onLoginSubmit={handleLoginFormSubmit}
          group={group}
          isLogin={true}
          loginError={loginError}
          onSignupSubmit={handleSignupFormSubmit}
          onForgotPasswordSubmit={handleForgotPasswordSubmit}
          resetLoginError={setLoginError}
        />
        {renderGroupSpecificContent()}
      </main>
    );
  };

  return <>{renderContent()}</>;
};

function getProgramGroupURL(token) {
  let progGroupURL = "https://opal.wellnesstoolbox.ca/?waa=";
  try {
    if (token) {
      const tokenArray = token.split(".");
      if (tokenArray?.length === 3) {
        const claims = JSON.parse(atob(tokenArray[1]));
        console.log(
          `claims : ${claims} program Group URL : ${claims["custom:program_group_url"]}`
        );
        return {
          program_group: claims["custom:program_group"],
          program_group_url: claims["custom:program_group_url"],
        };
      }
    }
  } catch (Error) {
    console.error(Error);
    return { program_group: "", program_group_url: progGroupURL };
  }
}

export default Login;
