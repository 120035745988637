import React from "react";
import "../styles/page.scss";

const Peace = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-9">
          <div class="front-page__text">
            <h2 class="wp-block-heading">
              Welcome to the PEACE program platform!
            </h2>

            <p>
              We are glad that you made the decision for your child to
              participate in our physiotherapy program!
            </p>

            <p>
              On this platform, you and your child will have access to exercise
              and educational videos, interactive activities, and online
              physiotherapy events.
            </p>

            <p>
              To help track your child’s progress, accumulate points towards
              your and your child’s rewards, and support the research project,{" "}
              <strong>
                we do kindly ask that you track your child’s exercise intake in
                the tracking Log section of this platform.
              </strong>
            </p>

            <p>
              Our research coordinator is available to assist you with any
              technical difficulties and inquiries about the platform. Please
              feel free to contact us if you have any questions!
            </p>

            <p>
              <strong>Phone: +1 (780) 492-6007</strong>
              <strong>
                <br />
              </strong>
              <strong>Email: </strong>
              <strong>pospina@ualberta.ca</strong>
            </p>

            <p>
              Sincerely,
              <br />
              <img
                loading="lazy"
                decoding="async"
                width="121"
                height="120.41597747802734"
                src="https://lh5.googleusercontent.com/Tc52fz_EwiuXybPrua-UgPjnTi60IXZkAUZdP1CyYHJjwKEDe6B5qS9XlDZ2FO3VMVCbeIDY79XcsXO5tr-p-FfNBdDqq6GHkdFNGMSnnIwlQ4DlWG27xiZhGTqLaf0HlkapEZzuqHsiHXABmr9xBg"
                alt="A picture containing graphics, graphic design, font, logo Description automatically generated"
              />
              <br />
              <strong>The PEACE Program Team</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Peace;
