import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./SignupForm.scss";
import TermsAndConditions from "../TermsAndConditions/TermsAndConditions";
import Overlay from "../Overlay/Overlay";
import passwordShowIcon from "../../assets/show-icon.png";
import passwordHideIcon from "../../assets/hide-icon.png";


const SignupForm = ({
  prepopulatedEmail,
  onLoginSubmit,
  onForgotPasswordSubmit,
  group,
  userValidated,
  isLogin,
  loginError,
  onSignupSubmit,
  resetLoginError,
  isLinkExpired,
}) => {
  const [email, setEmail] = useState(prepopulatedEmail || "");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [termsChecked, setTermsChecked] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [isSignupClicked, setIsSignupClicked] = useState(false);
  const newErrorMessages = [];
  const [errorMessages, setErrorMessages] = useState([]);
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  let btnText = isLogin ? "Log In" : "Sign Up";
  const isResetFlow = window.location.href.indexOf("reset") > -1;
  if (isResetFlow) {
    btnText = "Reset Password";
  }
  const [buttonText, setButtonText] = useState(btnText);

  const [isForgotPassword, setIsForgotPassword] = useState(false);

  const handleCheckboxChange = () => {
    setTermsChecked(!termsChecked);
  };

  const handleTermsClick = () => {
    setShowTermsModal(true);
  };

  const closeTermsModal = () => {
    setShowTermsModal(false);
  };
  const validatePassword = (inputPassword) => {
    const minLength = 6;
    const hasUppercase = /[A-Z]/.test(inputPassword);
    const hasLowercase = /[a-z]/.test(inputPassword);

    if (inputPassword.length < minLength || !hasUppercase || !hasLowercase) {
      return "Password must have at least 6 characters, atleast one uppercase letter and one lowercase letter.";
    }

    return "";
  };

  const handlePasswordChange = () => {
    const error = validatePassword(password);
    setPasswordError(error);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isForgotPassword) {
      if (isLogin) {
        if (!email.trim()) {
          newErrorMessages.push("Email cannot be empty.");
        }
        if (!password.trim()) {
          newErrorMessages.push("Password cannot be empty.");
        }

        setErrorMessages(newErrorMessages);

        if (newErrorMessages.length >= 1) {
          return;
        }
        if (newErrorMessages.length === 0) {
          onLoginSubmit({ email, password, rememberMe });
        }
      } else {
        setIsSignupClicked(true);
        handlePasswordChange();

        if (!password.trim()) {
          newErrorMessages.push("Password cannot be empty.");
        } else {
          handlePasswordChange();
          if (passwordError) {
            newErrorMessages.push(passwordError);
          }
        }

        if (!confirmPassword.trim()) {
          newErrorMessages.push("Confirm Password cannot be empty.");
        }

        if (password !== confirmPassword) {
          newErrorMessages.push("Password and Confirm Password must match.");
        }

        setErrorMessages(newErrorMessages);

        if (newErrorMessages.length >= 1 || !termsChecked) {
          return;
        }
        if (newErrorMessages.length === 0) {
          onSignupSubmit({ email, password, isResetFlow });
        }
      }
    }
  };

  const handleForgotPasswordClick = () => {
    setEmail("");
    setErrorMessages([]);
    resetLoginError(null);
    setButtonText("Reset Password");
    setIsForgotPassword(true);
  };

  const handleBackToLogin = () => {
    setEmail("");
    resetLoginError(null);
    const btnTxt = isLogin ? "Log In" : "Sign Up";
    setButtonText(btnTxt);
    setErrorMessages([]);
    setIsForgotPassword(false);
    setResetPasswordSuccess(false);
  };
  const handleResetPasswordSubmit = async (e) => {
    setErrorMessages([]);
    e.preventDefault();
    const result = await onForgotPasswordSubmit(email);
    if (result.success) {
      setResetPasswordSuccess(true);
    } else {
      newErrorMessages.push(
        "The email address you entered is incorrect. Please review your input and try again."
      );
      setErrorMessages(newErrorMessages);
    }
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const isSignupButtonDisabled = () => {
    if (isForgotPassword || resetPasswordSuccess) {
      return true;
    }

    if (isLogin) {
      return false;
    } else {
      return !termsChecked;
    }
  };

  useEffect(() => {
    isSignupButtonDisabled();
  }, [
    email,
    password,
    confirmPassword,
    termsChecked,
    isLogin,
    isForgotPassword,
    resetPasswordSuccess,
  ]);

  useEffect(() => {
    if (isLinkExpired) {
      window.location.href = `login?group=${group?.toLowerCase()}`;
    }
  }, [isLinkExpired, group]);
  return (
    <div className={`front-page__login ${group?.toLowerCase()}-bg`}>
      {userValidated || isLogin ? (
        <form
          onSubmit={isForgotPassword ? handleResetPasswordSubmit : handleSubmit}
        >
          <header className="wellness-card__header">
            <h2>{buttonText}</h2>
          </header>

          {!resetPasswordSuccess && !isResetFlow && (
            <p className="form-group">
              <label for="user_login">Email Address</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                readOnly={!!prepopulatedEmail}
                size="20"
                autoComplete="off"
                className={!isLogin && "form-group__email"}
              />
            </p>
          )}

          {!isForgotPassword && (
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="password-div">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <i
                  style={{
                    cursor: "pointer",
                    paddingRight: 10,
                  }}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <div
                    className="password-toggle-icon tooltip"
                    onClick={() => setShowPassword(!showPassword)}
                    title={showPassword ? "Hide Password" : "Show Password"}
                  >
                    {!showPassword ? (
                      <img
                        src={passwordShowIcon}
                        alt="show password"
                        height={20}
                      />
                    ) : (
                      <img
                        src={passwordHideIcon}
                        alt="show password"
                        height={20}
                      />
                    )}
                  </div>
                </i>
              </div>
            </div>
          )}

          {!isLogin && (
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm Password</label>
              <div className="password-div">
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <i
                  style={{
                    cursor: "pointer",
                    paddingRight: 10,
                  }}
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <div
                    className="password-toggle-icon tooltip"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    title={
                      showConfirmPassword ? "Hide Password" : "Show Password"
                    }
                  >
                    {!showConfirmPassword ? (
                      <img
                        src={passwordShowIcon}
                        alt="show password"
                        height={20}
                      />
                    ) : (
                      <img
                        src={passwordHideIcon}
                        alt="show password"
                        height={20}
                      />
                    )}
                  </div>
                </i>
              </div>
            </div>
          )}

          {!isLogin && (
            <div
              className={`form-group form-group__terms ${
                isSignupClicked && !termsChecked && "terms-error"
              }`}
            >
              <input
                type="checkbox"
                id="termsCheckbox"
                checked={termsChecked}
                onChange={handleCheckboxChange}
              />
              <label htmlFor="termsCheckbox" className="terms-label">
                I have read and agree to the{" "}
                <span className="terms-link" onClick={handleTermsClick}>
                  Terms Of Use
                </span>
              </label>
            </div>
          )}
          {errorMessages.length > 0 && (
            <div className="form-group">
              <div className="terms-error">
                {errorMessages.map((message, index) => (
                  <p key={index} className="error-message">
                    {message}
                  </p>
                ))}
              </div>
            </div>
          )}
          {!isForgotPassword && loginError && (
            <div className="form-group form-login">
              <div className="terms-error">
                <p className="error-message">{loginError}</p>
              </div>
            </div>
          )}
          {isLogin && !isForgotPassword && (
            <div className="form-group form-group__remember-me">
              <input
                type="checkbox"
                id="rememberMeCheckbox"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label htmlFor="rememberMeCheckbox" className="remember-me-label">
                Remember Me
              </label>
            </div>
          )}
          {isForgotPassword ? (
            resetPasswordSuccess ? (
              <div>
                <p>
                  Password reset email has been sent. Please check your email
                  inbox.
                </p>
                <div
                  className="back-to-login-btn back-to-login-btn__reset"
                  onClick={handleBackToLogin}
                >
                  Back to Login
                </div>
              </div>
            ) : (
              <div>
                <button type="submit" className="reset-pwd-btn">
                  Reset Password
                </button>
                <div className="back-to-login-btn" onClick={handleBackToLogin}>
                  Back to Login
                </div>
              </div>
            )
          ) : (
            <div>
              <button type="submit" disabled={isSignupButtonDisabled()}>
                {btnText}
              </button>
              {isLogin && (
                <div
                  className="forgot-password-link"
                  onClick={handleForgotPasswordClick}
                >
                  Forgot Password?
                </div>
              )}
            </div>
          )}
          {showTermsModal && <Overlay onClick={closeTermsModal} />}
          {showTermsModal && (
            <div className="terms-modal">
              <TermsAndConditions onClose={closeTermsModal} />
            </div>
          )}
        </form>
      ) : (
        <p>User does not exist</p>
      )}
    </div>
  );
};

SignupForm.propTypes = {
  prepopulatedEmail: PropTypes.string,
  onLoginSubmit: PropTypes.func.isRequired,
};

export default SignupForm;
