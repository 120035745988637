import React from "react";
import "../styles/page.scss";

const MyHeartCoach = () => {
  return (
    <>
    <div className="container">
  <div className="row justify-content-center">
    <div className="col-12 col-lg-9">
      <div className="front-page__text" style={{ textAlign: 'center',padding: '0 190px' }}>
        <h2 className="wp-block-heading">
          <strong>Welcome to the My Heart Coach program!</strong>
        </h2>

        <h3 className="wp-block-heading">
        Our team has put together a fantastic program that you can use over the next few weeks to help you with your Cardiac Rehab journey!
        </h3>

        <p>
          <strong>The program includes 3 sections:</strong>
        </p>

        <p>
          <em>Wellness</em>
        </p>

        <p>
          <em>Exercise</em>
        </p>
        <p>
          <em>Nutrition</em>
        </p>

        <p>
          Earn <strong>points and badges</strong> as you carry out your
          weekly activities!
        </p>

        <p>
          Stay motivated with a <strong>weekly leaderboard</strong>.
        </p>

        <p>
          Learn about others in the program through{' '}
          <strong>weekly polls</strong>!
        </p>
      </div>
    </div>
  </div>
</div>

    </>
  );
};

export default MyHeartCoach;
