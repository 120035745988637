import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import GroupPage from "./pages/GroupPage";
import Login from "./pages/Login";

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/login" component={Login} />
        <Route path="/group" component={GroupPage} />
        <Route path="/reset" component={GroupPage} />
        <Route path="/" component={Login} />
      </Switch>
    </Router>
  );
};

export default App;
