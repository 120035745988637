import React from "react";
import "../styles/page.scss";

const Prehab4All = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-9">
          <div class="front-page__text">
            <h2 class="wp-block-heading has-text-align-center">
              <strong>Welcome to your Prehabilitation4Me program! </strong>
            </h2>

            <h3 class="wp-block-heading has-text-align-center">
              Our team has put together a program that you can use over the next
              few weeks to help you prepare mentally and physically for your
              upcoming surgery!
            </h3>

            <p class="has-text-align-center">
              <strong>The program includes 4 sections:</strong>
            </p>

            <p class="has-text-align-center">
              <em>Eating Well</em>
            </p>

            <p class="has-text-align-center">
              <em>Being Active</em>
            </p>

            <p class="has-text-align-center">
              <em>Feeling Ready</em>
            </p>

            <p class="has-text-align-center">
              <em>Living Well</em>
            </p>

            <p class="has-text-align-center">
              Earn <strong>points and badges </strong>as you carry out your
              weekly activities!
            </p>

            <p class="has-text-align-center">
              Learn about others in the program through{" "}
              <strong>weekly polls</strong>!{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Prehab4All;
