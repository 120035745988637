import React from "react";
import "../styles/page.scss";

const Empower = () => {
    return (
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-lg-9">
            <div class="front-page__text">
              <h2 class="wp-block-heading has-text-align-center has-primary-color has-text-color">
                Welcome to your EMPOWER Wellness program!{" "}
              </h2>

              <p>
                Our team has put together a fantastic program to help reduce
                stress, improve your physical health, and foster a greater sense
                of overall well-being.{" "}
              </p>

              <p>The program includes 2 core sections:</p>

              <ul>
                <li>Energize</li>

                <li>Life Skills</li>
              </ul>

              <p>
                Earn <strong>points and badges </strong>as you carry out your
                weekly activities!
              </p>

              <p>
                Stay motivated with a <strong>weekly leaderboard</strong>.
              </p>

              <p>
                Learn about others in the program through{" "}
                <strong>weekly polls</strong>!{" "}
              </p>
            </div>
          </div>
        </div>
      </div>

    );
};

export default Empower;