import React from "react";
import "../styles/page.scss";

const Opal = () => {
  return (
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-9">
          <div class="front-page__text">
            <h2 class="wp-block-heading">
              <strong>Welcome to the OPAL program! </strong>
            </h2>

            <h3 class="wp-block-heading">
              Our team has put together a fantastic program that you can use
              over the next 12 weeks to help you prepare mentally and physically
              as you await your liver transplantation!
            </h3>

            <p>
              <strong>The program includes 3 sections:</strong>
            </p>

            <p>
              <em>Nutrition</em>
            </p>

            <p>
              <em>Exercise</em>
            </p>

            <p>
              <em>Life Skills</em>
            </p>

            <p>
              Earn <strong>points and badges </strong>as you carry out your
              weekly activities!
            </p>

            <p>
              Keep track of your goals with a handy{" "}
              <strong>tracking log. </strong>
            </p>

            <p>
              Stay motivated with a <strong>weekly leaderboard</strong>.
            </p>

            <p>
              Learn about others in the program through{" "}
              <strong>weekly polls</strong>!{" "}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Opal;
